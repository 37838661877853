<template>
  <div id="app">
    <vue-announcer />
    <forum-ui-pharos-toaster />
    <div v-if="isLoading">
      <header class="app-header">
        <div :class="{ test: !isProd }" class="navbar navbar-dark">
          <img alt="banner" src="./assets/j-forum-white-logo.png" class="logo" title="JSTOR Forum" />
        </div>
      </header>
      <loading-indicator text="Getting Forum ready" />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { mapActions, mapGetters, mapState } from 'vuex'
import Ethnio from '@/directives/Ethnio.js'
import LoadingIndicator from '@/components/common/LoadingIndicator'

export default {
  name: 'App',
  directives: {
    Ethnio
  },
  components: {
    LoadingIndicator
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      userEmail: state => state.user.email,
      profileId: state => state.user.profile_id,
      institutionId: state => state.user.institution_id
    }),
    ...mapGetters('user', ['loggedIn']),
    isProd() {
      return ['forum.jstor.org'].includes(document.domain)
    }
  },
  async mounted() {
    await this.fetchSession()

    const loggedIn = await this.fetchUser()
    this.fetchFeatureFlags()

    if (loggedIn) {
      await this.fetchInitialState()

      Sentry.configureScope(scope => {
        scope.setUser({
          email: this.userEmail,
          id: this.profileId
        })
      })
    }
    this.isLoading = false
    this.initStatusClient({
      componentId: this.isProd ? 'dqtfdmjwhyxs' : 'yfwwdgkmtrcv',
      environment: this.isProd ? 'prod' : 'test'
    })
  },
  methods: {
    ...mapActions('user', ['fetchSession', 'fetchUser']),
    ...mapActions('userState', ['fetchInitialState']),
    ...mapActions('banner', ['initStatusClient']),
    ...mapActions('featureFlags', ['fetchFeatureFlags'])
  }
}
</script>

<style lang="scss">
@import './common/bootstrap-local.scss';
@import './common/pharos-implementation.scss';
</style>

<style lang="scss" scoped>
.app-header {
  z-index: 1001;
  position: absolute;
  height: 80px;
  width: 100%;

  .navbar {
    height: 100%;
    background-color: $navBackgroundColor;
    padding: 1rem 1rem;
    align-items: flex-end;

    &.test {
      background-image: linear-gradient(19deg, $pharos-color-glacier-blue-40 0%, $pharos-color-night-blue-15 100%);
    }
  }

  .logo {
    height: 41px;
    vertical-align: middle;
  }
}
</style>
