import axios from 'axios'
import { request } from '@/common/async.js'

/**
 * @description get a list of asset group assets, optionally filtered by params
 * @param {string} assetGroupId asset group id
 * @param {object} options axios get options
 * @param {object} fetchProjectSource axios cancel token source
 * @returns {Promise} axios request promise
 */
export function queryAssetGroupAssets(assetGroupId, options, fetchProjectSource) {
  if (fetchProjectSource && fetchProjectSource.signal) {
    fetchProjectSource.abort()
  }
  Object.assign(fetchProjectSource, new AbortController())

  const { params } = options
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/asset-groups/${assetGroupId}/assets?${queryParams}`, {
    signal: fetchProjectSource.signal
  })
}

/**
 * @description saves an asset group's assets
 * @param {string} assetGroupId asset group id
 * @param {array} assetIds list of asset ids
 * @returns {Promise} axios request promise
 */
export function saveAssetGroupAddAssets(assetGroupId, assetIds) {
  const assetData = new FormData()
  assetIds.forEach(id => {
    assetData.append('asset_ids', id)
  })

  return request({ url: `_bulk/asset-groups/${assetGroupId}/add/assets`, method: 'POST', data: assetData })
}

/**
 * @description create assets for an asset group (set) from a given excel file
 * @param {string} assetGroupId asset group id
 * @param {blob} file excel file containing asset data
 * @returns {Promise} axios request promise
 */
export function saveAssetGroupAssetsFile(assetGroupId, file) {
  const formData = new FormData()
  formData.append('data_file', file)

  return request({
    url: `/asset-groups/${assetGroupId}/assets.xls`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Disposition': 'form-data',
      'Content-Type': 'application/octet-stream'
    }
  })
}

/**
 * @description delete a single asset group for a project
 * @param {string} projectId project id
 * @param {string} sets ids of all the sets to delete
 * @returns {Promise} axios request promise
 */
export function deleteMultipleProjectAssetGroup(projectId, sets) {
  const formData = new FormData()
  formData.append('asset_group_ids', sets.join('|'))
  return axios.post(`/projects/${projectId}/asset-groups/delete`, formData)
}
