import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

// Install the vue plugin
Vue.use(VueApollo)

const forumEndpoint = '/graphql/v1'
const notificationsEndpoint = '/notifications/v1/graphql'
const federatedGraphqlEndpoint = '/ui/data-fetch/gateway'

// TODO: remove after we have migrate to iac-aaa service for user list page
const v2SessionEndpoint = '/unfederated-session-service/query'

const defaultOptions = {
  persisting: false,
  websocketsOnly: true,
  ssr: false
}

export const { apolloClient: federatedGraphqlClient } = createApolloClient({
  ...defaultOptions,
  httpEndpoint: federatedGraphqlEndpoint,
  getAuth: () => {
    return 'forum-ui'
  }
})

// TODO: remove after we have migrate to iac-aaa service for user list page
export const { apolloClient: v2SessionClient } = createApolloClient({
  ...defaultOptions,
  httpEndpoint: v2SessionEndpoint,
  getAuth: () => {
    return 'forum-ui'
  }
})

export const { apolloClient: forumClient } = createApolloClient({
  ...defaultOptions,
  httpEndpoint: forumEndpoint
})

export const { apolloClient: notificationsClient } = createApolloClient({
  ...defaultOptions,
  httpEndpoint: notificationsEndpoint
})

export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      forum: forumClient,
      notifications: notificationsClient,
      federatedGraphql: federatedGraphqlClient
    },
    defaultClient: forumClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    }
  })

  return apolloProvider
}
