export const FILE_MEDIA_SOURCE_ID = 100
export const DRS_MEDIA_SOURCE_ID = 102
export const MEDIA_SOURCE_ID = 106
export const ROTATABLE_IMAGE_MIMES = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png'
}

export const THUMBNAIL_SIZE_ERR =
  'Please check your image dimensions. If the issue persists, contact support@jstor.org.'
export const THUMBNAIL_UPLOAD_ERR =
  'Due to technical issues on our end we could not upload your thumbnail. Please try again. If the issue persists, contact support@jstor.org'
export const TRANSCRIPT_UPLOAD_ERR =
  'Due to technical issues on our end we could not upload your transcript. Please try again. If the issue persists, contact support@jstor.org'
export const FILE_TYPE_ERR = 'Please upload a valid file'
export const TRANSCRIPT_FILE_TYPE_ERR =
  'We could not upload your transcript due to an error in the file format. Contact support@jstor.org if you need assistance.'
