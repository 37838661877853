export const supportedImgExtensions = [
  '.tif',
  '.jpg',
  '.png',
  '.tiff',
  '.jpeg',
  '.jp2',
  '.jpx',
  '.j2k',
  '.jpf',
  '.j2c',
  '.jpc',
  '.crw',
  '.cr2',
  '.dng',
  '.zvi',
  '.gif',
  '.ai',
  '.eps',
  '.psd',
  '.svg',
  '.bmp',
  '.nef'
]
export const supportedAudioExtensions = ['.wav', '.mp3']
export const supportedVideoExtensions = ['.asf', '.qt', '.mov', '.mpg', '.mpeg', '.avi', '.wmv', '.mp4', '.m4v', '.3gp']

// All file types
export const supportedExtensions = [
  ...supportedImgExtensions,
  ...supportedAudioExtensions,
  ...supportedVideoExtensions,
  '.pdf'
]
