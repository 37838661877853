import gridColumnState from './state/gridColumnState'
import { getState, saveState } from '../services/state'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { getProjectFilterStateKey, getProjectFavoriteFiltersStateKey, getUploadDetailsKey } from '../common/userState'
import { captainsLog } from '@/services/captainsLog'

const INITIAL_STATE = ['user', 'project-panel']

const defaultState = () => ({
  loadedStates: {},
  data: {
    user: {},
    'feature-indicators': {},
    'feature-feedback': {},
    uploads: {},
    'project-access': {}
  },
  loaded: false,
  MAX_PINNED_FILTERS: 4
})

const userState = {
  namespaced: true,
  modules: {
    gridColumnState
  },
  state: defaultState(),
  mutations: {
    FETCHED_STATE(state, options) {
      Vue.set(state.loadedStates, options.key, true)
      Vue.set(state.data, options.key, options.state[options.key])
      state.loaded = true
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    UPDATE_PROJECT_ID(state, options) {
      const { key, projectId } = options

      Vue.set(state.data, key, {
        project: {
          id: projectId
        }
      })
    },
    UPDATE_PROJECT_COLUMNS(state, options) {
      const { gridState, columns } = options
      Vue.set(state.data, gridState, { columns })
    },
    CLEAR_PROJECT_STATE(state, options) {
      const { gridState } = options
      Vue.set(state.data, gridState, {})
    },
    UPDATE_PROJECT_PANEL_STATE(state, panelState) {
      const panelConfig = state.data[`project-panel`] || {}
      Object.assign(panelConfig, panelState)
      state.data['project-panel'] = panelConfig
    },
    UPDATE_FEATURE_FEEDBACK_STATE(state, options) {
      const { featureId, feedbackState } = options
      Vue.set(state.data['feature-feedback'], featureId, feedbackState)
    },
    UPDATE_FEATURE_INDICATOR_STATE(state, options) {
      const { featureId, value } = options
      Vue.set(state.data['feature-indicators'], featureId, value)
    },
    UPDATE_STATE(state, options) {
      const { key, value } = options
      Vue.set(state.data, key, value)
    },
    UPDATE_USER_STATE(state, options) {
      const { key, value } = options
      Vue.set(state.data.user, key, value)
    }
  },
  actions: {
    async fetchInitialState(context) {
      const initialStates = ['user', 'project-panel', 'project-access', 'feature-feedback']
      return Promise.all(initialStates.map(state => context.dispatch('fetchState', state)))
    },
    // this function shouldn't be called directly, it simply posts whatever's in state
    async _saveState(context, key) {
      if (!context.state.loaded) {
        return
      }

      try {
        const token = context.rootState?.user.token
        const res = await saveState(token, key, context.state.data[key])
        return res.data.success
      } catch (err) {
        console.error(err)
        return false
      }
    },
    _updateUserState(context, options) {
      const { key, value } = options
      context.commit('UPDATE_USER_STATE', { key, value })
      return context.dispatch('_saveState', 'user')
    },
    _updateState(context, options) {
      const { key, value } = options
      context.commit('UPDATE_STATE', { key, value })
      return context.dispatch('_saveState', key)
    },
    // filter uploads that are older than a month
    _filterUploads(context, uploads) {
      const now = new Date()
      const oneMonth = 1000 * 60 * 60 * 24 * 30
      const uniques = []
      return uploads
        .filter(e => {
          if (uniques.includes(e.date)) {
            return false
          }
          uniques.push(e.date)
          const start = new Date(e.date)
          const diff = now - start
          return diff < oneMonth
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
    // save the selected project id to state
    async saveProjectIdState(context, options) {
      let { projectId, type } = options
      const key = `imata-${type}-list`

      if (get(context.state.data, `${key}.project.id`) === projectId) {
        return
      }

      context.commit('UPDATE_PROJECT_ID', {
        key,
        projectId
      })

      context.dispatch('_saveState', key)
    },
    async updateProjectColumns(context, options) {
      let { gridState, columns } = options

      context.commit('UPDATE_PROJECT_COLUMNS', {
        gridState,
        columns
      })

      await context.dispatch('_saveState', gridState)
    },
    async clearState(context, options) {
      let { gridState } = options
      context.commit('CLEAR_PROJECT_STATE', {
        gridState
      })

      context.dispatch('_saveState', gridState)
    },
    async captainsLog(context, event) {
      const token = context.rootState?.user.token
      const user = context.rootState?.user
      const institution_id = user?.institution_eid
      const user_id = user?.profile_id
      const institution = context.rootState?.institutions.data.find(
        institution => institution.id === user?.institution_id
      )

      captainsLog(token, {
        ...event,
        user_agent: navigator.userAgent,
        institution_id,
        user_id,
        institution_name: institution?.name
      }).then(res => {
        Vue.prototype.$cookies?.set('CL-event-id', res.data.eventid)
      })
    },
    async fetchState(context, key) {
      if (!key) {
        return
      }

      if (context.state.loadedStates[key]) {
        return context.state.data[key]
      }

      try {
        const token = context.rootState?.user.token
        const res = await getState(token, key)

        await context.commit('FETCHED_STATE', { key, state: res.data })
        if (key === 'uploads') {
          await context.dispatch('saveUploadState')
        }

        return res.data[key]
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    /*
    Saves the timestamp when a feature is used so that the feedback widget
    can be shown to the user after an adjustable amount of time
    Parameters:
    options:
    { featureId: 'name-of-feature',
     feedbackState: timestamp or true if user has given feedback }
    */
    async saveFeatureFeedbackState(context, options) {
      context.commit('UPDATE_FEATURE_FEEDBACK_STATE', options)
      context.dispatch('_saveState', 'feature-feedback')
    },
    async saveProjectPanelState(context, state) {
      context.commit('UPDATE_PROJECT_PANEL_STATE', state)
      context.dispatch('_saveState', 'project-panel')
    },
    copyProjectState(context, options) {
      const { projectId, setId, filterId } = options
      let gridState
      if (filterId) {
        gridState = `filter-${filterId}-asset-grid`
      } else if (setId) {
        gridState = `set-${setId}-asset-grid`
      } else {
        return
      }

      const fromState = `project-${projectId}-asset-grid`
      const columnsDefs = context.rootGetters[`projects/getColumns`](fromState)
      if (columnsDefs && columnsDefs.length) {
        const columns = columnsDefs.map(col => ({
          width: col['width'],
          hidden: col['hide'],
          id: col['id']
        }))
        context.dispatch('updateProjectColumns', { gridState, columns })
      }
    },
    async saveFeatureIndicatorState(context, options) {
      let { featureId, value } = options
      context.commit('UPDATE_FEATURE_INDICATOR_STATE', {
        featureId,
        value
      })
      context.dispatch('_saveState', 'feature-indicators')
    },
    acceptTerms(context, version) {
      return context.dispatch('_updateState', {
        key: 'terms-agreed',
        value: { version, date: new Date().toISOString() }
      })
    },
    acceptPublishingTerms(context, target) {
      const version = context.getters.getPublishingTerms(target)
      return context.dispatch('_updateState', {
        key: `${target}-terms-agreed`,
        value: { version, date: new Date().toISOString() }
      })
    },
    saveItemsPerPage(context, value) {
      return context.dispatch('_updateUserState', { key: 'items-per-page', value })
    },
    saveProductTourState(context, value) {
      return context.dispatch('_updateUserState', { key: 'product-tour', value })
    },
    saveCatalogScreen(context, options) {
      const { projectId, screenId } = options
      const key = `project-${projectId}-catalog-screen`
      return context.dispatch('_updateUserState', { key, value: screenId })
    },
    saveMasterRecord(context, options) {
      const { projectId, assetId } = options
      const key = `project-${projectId}-master-record`
      return context.dispatch('_updateUserState', { key, value: assetId })
    },
    saveProjectViewState(context, value) {
      return context.dispatch('_updateUserState', { key: 'project-view', value })
    },
    saveThumbnailSizeState(context, value) {
      return context.dispatch('_updateUserState', { key: 'thumbnail-size', value })
    },
    saveBetaFilterState(context, value) {
      return context.dispatch('_updateUserState', { key: 'beta-filters', value })
    },
    async saveUploadState(context, value) {
      const key = 'uploads'
      let uploads = cloneDeep(context.state.data[key].data) || []
      const cache = JSON.parse(localStorage.getItem(key) || '[]')
      if (value) {
        cache.push(value)
      }
      uploads = await context.dispatch('_filterUploads', uploads.concat(cache))
      for (const upload of uploads) {
        if (upload.details && upload.details.length) {
          const key = getUploadDetailsKey(upload)
          await context.dispatch('_updateState', { key, value: { data: upload.details } })
          delete upload['details']
        }
      }

      const success = await context.dispatch('_updateState', { key, value: { data: uploads } })
      if (success) {
        localStorage.removeItem(key)
      } else if (cache.length > 0) {
        localStorage.setItem(key, JSON.stringify(cache))
      }
      return success
    },
    appliedFilter(context, { projectId, filter }) {
      const key = getProjectFilterStateKey(projectId)
      const currentState = cloneDeep(context.state.data[key]) || {}
      const count = get(currentState, [filter, 'count']) || 0
      currentState[filter] = { date: new Date(), count: count + 1 }
      return context.dispatch('_updateState', { key, value: currentState })
    },
    pinFilter(context, { projectId, dataIndex, header, unpin }) {
      const key = getProjectFavoriteFiltersStateKey(projectId)
      const currentState = cloneDeep(context.state.data[key]) || {}
      if (!currentState.data) {
        currentState.data = []
      }

      const maxPinnedFilters = context.getters.maxPinnedFilters
      const index = currentState.data.findIndex(e => e.dataIndex === dataIndex)
      if (unpin && index !== -1) {
        currentState.data.splice(index, 1)
      } else if (!unpin && index === -1 && currentState.data.length < maxPinnedFilters) {
        const systemIndex = currentState.data.findIndex(e => e.system)
        if (systemIndex === -1) {
          currentState.data.push({ dataIndex, title: header })
        } else {
          currentState.data.splice(systemIndex, 1, { dataIndex, title: header })
        }
      }

      if (currentState.data.length > maxPinnedFilters) {
        currentState.data = currentState.data.slice(0, maxPinnedFilters)
      }
      return context.dispatch('_updateState', { key, value: currentState })
    },
    setPinnedFilters(context, { projectId, filters }) {
      const key = getProjectFavoriteFiltersStateKey(projectId)
      const currentState = cloneDeep(context.state.data[key]) || {}
      currentState.data = filters
      currentState.initialized = true
      return context.dispatch('_updateState', { key, value: currentState })
    },
    accessedProject(context, projectId) {
      const key = 'project-access'
      const currentState = cloneDeep(context.state.data[key]) || {}
      currentState[projectId] = new Date()
      return context.dispatch('_updateState', { key, value: currentState })
    },
    dismissWorkflowNotifcation(context, key) {
      context.dispatch('_updateUserState', {
        key: `hide-${key}-notification`,
        value: true
      })
    }
  },
  getters: {
    hideWorkflowNotification: state => key => state.data.user[`hide-${key}-notification`],
    hasInitialState: state => {
      return INITIAL_STATE.every(key => state.loadedStates[key])
    },
    projectPanelState: state => {
      return (
        state.data[`project-panel`] || {
          width: '250px',
          collapsed: false
        }
      )
    },
    getGridStateForProject: state => gridState => {
      let grid = state.data[gridState]
      if (!grid) {
        grid = { columns: [] }
      }

      return grid
    },
    getFeatureIndicator: state => featureId => {
      return state.data[`feature-indicators`] && state.data[`feature-indicators`][featureId]
    },
    //Checks whether feedback has been triggered for this user and on what time
    getFeatureFeedbackStatus: state => featureId => {
      return state.data[`feature-feedback`] && state.data[`feature-feedback`][featureId]
    },
    projectViewState: state => state.data.user[`project-view`] || 'grid',
    thumbnailSizeState: state => state.data.user[`thumbnail-size`] || 0,
    viewingBetaFilters: state => !!state.data.user[`beta-filters`],
    termsAgreed: state => state.data[`terms-agreed`],
    getPublishingTerms: (state, getters, rootState) => target => {
      return target === 'artstor' ? rootState.banner.artstorTermsVersion : rootState.banner.jstorTermsVersion
    },
    isAgreedToTerms: (state, getters) => target => {
      const currentVersion = getters.getPublishingTerms(target)
      const termsAgreed = state.data[`${target}-terms-agreed`]
      return !!termsAgreed && termsAgreed.version === currentVersion
    },
    uploads: state => state.data.uploads[`data`] || [],
    getAppliedFilters: state => key => state.data[key],
    getPinnedFiltersData: state => key => state.data[key],
    maxPinnedFilters: state => state.MAX_PINNED_FILTERS
  }
}

export default userState
